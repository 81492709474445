.breadcrumbs {
    display: flex;
    margin-bottom: 15px;
    padding-left: 0;

    li {
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        text-transform: lowercase;
    }

    li + li:before {
        content: ">";
        font-weight: normal;
        margin-left: 5px;
        margin-right: 5px;
    }

    .active {
        cursor: default;
        font-weight: normal;
    }
}
