.slideshow {
    @include media("<=phone") {
        align-items: center !important;
        display: grid !important;
        gap: 0;
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr !important;
    }

    .slide {
        @include media("<=phone") {
            grid-column: 1/2;
            grid-row: 1/2;
            opacity: 0;
        }

        &.active {
            opacity: 1;
        }
    }
}

.slideshow-pagination {
    display: none;

    @include media("<=phone") {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    button {
        padding: 12px;

        .label {
            display: none;
        }

        .bullet {
            background-color: var(--abbey);
            border: 2px solid var(--abbey);
            border-radius: 50%;
            display: block;
            height: 10px;
            width: 10px;
        }
    }

    button.active .bullet {
        background-color: transparent;
    }

    button + button {
        margin-left: 8px;
    }
}
