html {
    box-sizing: border-box;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    color: var(--black) !important;
    font-family: "Calligaris", sans-serif !important;
    font-size: 14px;
    line-height: 17px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit !important;
    text-decoration: none !important;
    transition: all 0.3s linear;
}

a:hover {
    color: var(--black) !important;
}

img {
    display: block;
    max-width: 100%;
}
