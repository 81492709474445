.modal {
    .modal-dialog {
        margin-top: 80px;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        padding: 50px 60px 55px;
    }

    .title {
        font-size: 36px;
        font-weight: bold;
        line-height: 43px;
        margin-bottom: 16px;
    }

    .description {
        margin-bottom: 32px;
    }

    .actions {
        align-items: center;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 32px;
    }
}
