.configurable.main {
    .search-form {
        margin-top: 40px;

        @include media("<=phone") {
            margin-top: 10px;
        }

        form {
            display: grid;
            gap: 24px;
            grid-template-columns: 1fr 180px;

            @include media("<=phone") {
                gap: 5px;
                grid-template-columns: 1fr;
            }
        }

        .search-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;

            button {
                margin-right: -20px;
                padding: 0 20px;
                width: auto;

                svg {
                    width: 18px;
                }
            }
        }

        .category-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;
            min-height: 51px;

            .dropdown-toggle {
                justify-content: space-between;
            }

            .dropdown,
            .dropdown-menu {
                width: 100%;
            }
        }
    }

    .grid {
        display: grid;
        gap: 26px 24px;
        grid-template-columns: repeat(2, 1fr);

        @include media("<=phone") {
            grid-template-columns: 1fr;
            gap: 36px;
        }
    }
}
