.rbt {
    .dropdown-toggle {
        align-items: center;
        display: flex;
        font-weight: bold;
        padding: 0;
        text-transform: lowercase;

        &::after {
            background-image: url("./../../img/icons/chevron.svg");
            background-position: center;
            background-repeat: no-repeat;
            border: 0;
            height: 12px;
            margin-left: 10px;
            vertical-align: 0;
            width: 12px;
        }
    }

    .rbt-menu {
        padding: 20px 0;
    }

    .dropdown-item {
        font-weight: bold;
        padding: 12px 32px;
        text-transform: lowercase;
        transition: color 0.2s;

        &.active,
        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
            color: var(--valencia) !important;
        }
    }

    .rbt-menu-pagination-option {
        padding-bottom: 0;
    }
}
