$breakpoints: (
    phone: 750px,
    tablet: 1024px,
    desktop: 1920px,
);

.container {
    padding: 0 40px !important;
    max-width: 1424px !important;

    @include media("<=phone") {
        padding: 0 10px !important;
    }
}
