
.product-detail {
    .phone-container {
        margin-bottom: 60px;

        @include media("<=phone") {
            display: flex;
            flex-direction: column;
            min-height: calc(
                100vh - 202px
            ); // 80px .header height + 40px .page-header padding-top + 82px .add-to-cart > .right height
        }
    }

    .page-header {
        align-items: end;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);

        @include media("<=phone") {
            display: block;
        }

        .right {
            justify-self: end;

            @include media("<=phone") {
                margin-top: 16px;
            }
        }

        .title {
            font-size: 36px;
            line-height: 43px;

            @include media("<=phone") {
                font-size: 30px;
                line-height: 36px;
            }
        }

        .subtitle {
            font-size: 36px;
            line-height: 43px;
            margin-bottom: 0;

            @include media("<=phone") {
                font-size: 30px;
                line-height: 36px;
            }
        }

        .procurement-method {
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            text-transform: lowercase;

            .icon {
                margin-right: 8px;
                width: 32px;
            }
        }
    }

    .images {
        align-items: end;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 30px;

        @include media("<=phone") {
            display: block;
            flex: 1;
        }

        img {
            width: 100%;
        }

        .left{
            height: 100%;
            @include media("<=phone") {
                height: auto;
            }
        }

        .right.slide.rendered{
            height: 100%;
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            img {
                height: 100%;
                object-fit: contain;
                display: flex;
                flex: 1;
                position: relative;
            }
        }

        .info {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 11px;

            @include media("<=phone") {
                display: none;
            }
        }

        .more-info {
            align-items: center;
            background-color: transparent;
            border: 0;
            color: var(--abbey);
            display: flex;
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            padding: 0;
            text-transform: lowercase;

            @include media("<=phone") {
                display: none;
            }

            .label {
                flex-shrink: 0;
            }

            svg {
                flex-shrink: 0;
                margin-left: 8px;
                transform: rotate(90deg);
                width: 13px;
            }
        }
    }

    .right.filters {
        @include media("<=phone") {
            margin: 20px 10px 0;
        }
    }

    .right.filters > div + div {
        margin-top: 24px;
    }

    .right.filters .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        margin-bottom: 8px;
        margin-left: 40px;
        text-transform: lowercase;
    }

    .right.filters input {
        font-weight: bold;
        text-transform: lowercase;
    }

    .right.filters input::placeholder {
        font-weight: normal;
    }

    .add-to-cart {
        border: 1px solid var(--iron);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;

        @include media("<=phone") {
            border: 0;
            display: block;
            margin-bottom: 0;
        }

        .left {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            min-height: 60px;

            @include media("<=phone") {
                border: 1px solid var(--iron);
                margin: 0 10px 20px;
            }
        }

        .price {
            align-items: center;
            border-right: 1px solid var(--iron);
            display: flex;
            font-size: 24px;
            font-weight: bold;
            justify-content: center;
            line-height: 29px;

            .tmp {
                font-size: 14px;
                line-height: 18px;
                max-width: 200px;
                padding: 20px 10px;
                text-align: center;
                text-transform: lowercase;
            }
            .error {
                color: red;
            }
        }

        .pieces {
            align-items: center;
            border-right: 1px solid var(--iron);
            display: flex;
            font-size: 24px;
            font-weight: bold;
            justify-content: center;
            line-height: 29px;

            @include media("<=phone") {
                border: 0;
            }

            > div {
                position: relative;

                svg {
                    height: 42px;

                    @include media("<=phone") {
                        height: 30px;
                    }
                }

                span {
                    font-size: 18px;
                    left: 60px;
                    line-height: 18px;
                    position: absolute;
                    top: 20px;

                    @include media("<=phone") {
                        font-size: 16px;
                        line-height: 16px;
                        top: 18px;
                    }
                }
            }
        }

        .right {
            display: grid;
            gap: 10px;
            grid-template-columns: min-content auto;
            padding: 10px;

            @include media("<=phone") {
                background-color: var(--white);
                border: 1px solid var(--iron);
                border-width: 1px 0;
                bottom: 0;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                margin: 0 -10px;
                position: fixed;
                width: 100%;
            }
        }

        .quantity {
            border: 1px solid var(--iron);
            display: flex;
            height: 60px;

            button {
                align-items: center;
                background-color: transparent;
                border: 0;
                display: flex;
                justify-content: center;
                width: 60px;

                .label {
                    display: none;
                }

                svg {
                    width: 14px;
                }
            }

            button:disabled {
                background-color: var(--whild-sand);
            }

            > span {
                align-items: center;
                border: 1px solid var(--iron);
                border-width: 0 1px;
                display: flex;
                font-size: 24px;
                font-weight: bold;
                flex: 1;
                justify-content: center;
                line-height: 29px;
                overflow: hidden;
                width: 60px;
            }
        }
    }

    .general-info{
        border: 1px solid var(--iron);
        padding: 20px 40px;
        display: flex;
        justify-content: space-around;
        column-gap: 20px;

        @include media("<=phone") {
            margin: 0 10px 20px;
            padding: 15px 30px;
            flex-direction: column;
            row-gap: 10px;
        }

        .info-section{
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            max-width: 25%;
            @include media("<=phone"){
                max-width: unset;
            }
            span{
                line-height: 19px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .title{
                font-weight: bold;
            }
        }
        @include media(">phone") {
            .deliveryTime-section {
                max-width: 30%;
            }
        }
    }

    .description-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        margin-bottom: 32px;
        padding: 0 40px;
        text-transform: lowercase;

        @include media("<=phone") {
            padding: 0 10px;
        }
    }

    .description {
        display: grid;
        gap: 72px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 40px;

        @include media("<=phone") {
            display: block;
            margin: 0 10px;
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 29px;
            margin-bottom: 32px;
            text-transform: lowercase;
        }

        div {
            line-height: 19px;
        }

        .left{
            display: flex;
            flex-direction: column;

            .link{
                align-self: flex-end;
                font-size: 14px;
                padding: 5px 10px;
                border: solid 1px;
                border-radius: 2px;
            }

        }

        .right {
            @include media("<=phone") {
                margin-top: 40px;
            }
        }

        table {
            width: 100%;

            th {
                font-weight: bold;
                text-transform: lowercase;
                width: 30%;
            }

            tr + tr {
                th,
                td {
                    padding-top: 16px;
                }
            }
        }
    }

    .description + .description {
        border-top: 1px solid var(--iron);
        margin-top: 60px;
        padding-top: 60px;
    }

    + .footer {
        @include media("<=phone") {
            margin-bottom: 82px; // .add-to-cart > .right height
        }
    }
}
