.cart {
    .grid {
        align-items: start;
        display: grid;
        gap: 24px;
        grid-template-columns: 3fr minmax(300px, 1fr);

        @include media("<=tablet") {
            grid-template-columns: 1fr;
            gap: 60px;
        }

        .products {
            table {
                border: 1px solid var(--iron);
                width: 100%;

                tr {
                    @include media("<=phone") {
                        display: grid;
                    }
                }

                tr + tr {
                    border-top: 1px solid var(--iron);
                }

                td {
                    vertical-align: middle;
                }

                .info {
                    padding: 20px 10px;
                }

                .image-details {
                    align-items: center;
                    display: flex;
                }

                .image {
                    flex-shrink: 0;
                    width: 130px;
                }

                .details {
                    font-size: 16px;
                    line-height: 19px;
                    padding-left: 10px;

                    .title {
                        font-weight: bold;
                        text-transform: lowercase;
                    }

                    .options {
                        font-size: 14px;
                        line-height: 18px;
                        margin-top: 10px;
                        text-transform: lowercase;

                        span {
                            display: block;
                        }
                    }
                }

                .dimension-icon{
                    display: flex;
                    width: 120px;
                }

                .actions {
                    padding: 20px 24px 20px 10px;
                    width: 1%;

                    @include media("<=phone") {
                        padding-top: 0;
                    }

                    .remove {
                        font-size: 14px;
                        line-height: 17px;
                        padding: 16px;
                    }
                }

                .actions-wrapper {
                    display: flex;
                }

                .quantity {
                    align-items: center;
                    border: 1px solid var(--iron);
                    display: flex;
                    margin-right: 10px;

                    button {
                        align-items: center;
                        background-color: transparent;
                        border: 0;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        width: 50px;

                        .label {
                            display: none;
                        }

                        svg {
                            width: 14px;
                        }
                    }

                    .input {
                        align-items: center;
                        border: 1px solid var(--iron);
                        border-width: 0 1px;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        padding: 10px;
                        width: 50px;
                    }
                }
            }
        }

        .personal-details-inputs{
            margin-top: 40px;

            .email-input{
                display: flex;
                justify-content: space-between;
                width: 540px;
                @include media("<=phone") {
                    width: 100%;
                }
                p{
                    align-self: flex-end;
                    color: var(--valencia);
                    font-size: 12px;
                    margin-bottom: 10px;
                    margin-top: 15px;
                    line-height: 14px;
                }
            }

            .label{
                margin-left: 40px;
                margin-top: 15px;
            }
            .personal-details-input{
                transition: 0s;
                width: 540px;
                padding: 16px 40px;
                @include media("<=phone") {
                    width: 100%;
                }
            }
        }

        .notes {
            margin-top: 40px;

            h2 {
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                margin: 0 40px 10px;
                text-transform: lowercase;

                @include media("<=tablet") {
                    margin: 0 10px 10px;
                }
            }

            textarea {
                border: 1px solid var(--iron);
                border-radius: 0;
                height: 150px;
                outline: 0;
                padding: 20px 40px;
                resize: none;
                transition: all 0.2s;
                width: 100%;

                &:focus {
                    border: 1px solid var(--black);
                }

                &::placeholder {
                    text-transform: lowercase;
                }

                @include media("<=tablet") {
                    padding: 20px 24px;
                }
            }
        }

        .subtotal-row {
            align-items: center;
            border: 1px solid var(--iron);
            border-top: 0;
            display: flex;
            justify-content: space-between;
            padding: 20px 24px 20px 10px;

            .left {
                align-items: center;
                display: flex;
                text-transform: lowercase;
            }

            .pictogram {
                margin-right: 10px;
                width: 110px;

                svg {
                    display: block;
                    margin: auto;
                    width: 42px;
                }
            }
        }

        & > .right {
            border: 1px solid var(--iron);

            .title {
                border-bottom: 1px solid var(--iron);
                font-size: 16px;
                font-weight: bold;
                line-height: 19px;
                margin-bottom: 0;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .subtotal {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .transportation-fee {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 10px 24px;
                text-transform: lowercase;
            }

            .transportation-fee-input {
                align-items: stretch;
                border: 1px solid var(--iron);
                display: flex;
                height: 50px;

                input[type="number"] {
                    border: 0;
                    border-right: 1px solid var(--iron);
                    border-radius: 0;
                    outline: 0;
                    padding: 10px;
                    text-align: center;
                    width: 52px;
                }

                .controls {
                    display: flex;
                    flex-direction: column;
                    width: 46px;

                    button {
                        background: transparent;
                        border: 0;
                        flex: 1;
                        padding: 0;

                        .label {
                            display: none;
                        }

                        svg {
                            width: 12px;
                        }
                    }

                    .increase {
                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    button + button {
                        border-top: 1px solid var(--iron);
                    }
                }

                .symbol {
                    align-items: center;
                    border-left: 1px solid var(--iron);
                    display: flex;
                    justify-content: center;
                    width: 52px;
                }
            }

            .discount {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 10px 24px;
                text-transform: lowercase;
            }

            .discount-input {
                align-items: stretch;
                border: 1px solid var(--iron);
                display: flex;
                height: 50px;

                input[type="number"] {
                    border: 0;
                    border-right: 1px solid var(--iron);
                    border-radius: 0;
                    outline: 0;
                    padding: 10px;
                    text-align: center;
                    width: 52px;
                }

                .controls {
                    display: flex;
                    flex-direction: column;
                    width: 46px;

                    button {
                        background: transparent;
                        border: 0;
                        flex: 1;
                        padding: 0;

                        .label {
                            display: none;
                        }

                        svg {
                            width: 12px;
                        }
                    }

                    .increase {
                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    button + button {
                        border-top: 1px solid var(--iron);
                    }
                }

                .symbol {
                    align-items: center;
                    border-left: 1px solid var(--iron);
                    display: flex;
                    justify-content: center;
                    width: 52px;
                }
            }

            .total {
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;

                .discounted-total {
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 43px;
                }
            }

            .actions {
                padding: 0 24px 24px;

                button {
                    width: 100%;
                }

                button + button {
                    margin-top: 16px;
                }
            }
        }
    }

    .empty-cart {
        font-size: 36px;
        font-weight: bold;
        line-height: 43px;
        margin-top: 80px;
    }
}

.cart-popover {
    .empty-cart-popover {
        padding: 32px;
        text-align: center;
        text-transform: lowercase;
    }

    .products {
        padding-right: 14px;
        max-height: 60vh;
        overflow-y: auto;
    }

    table {
        width: 100%;

        td {
            padding: 10px;
            vertical-align: middle;
        }

        td + td {
            padding-left: 0;
        }

        .image {
            width: 140px;
        }

        .quantity {
            text-transform: lowercase;
        }

        .delete {
            text-align: right;
            text-transform: lowercase;

            a {
                cursor: pointer;
            }
        }
    }

    .subtotal {
        align-items: center;
        border: 1px solid var(--iron);
        border-width: 1px 0;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;

        .label {
            text-transform: lowercase;
        }

        .price {
            font-size: 24px;
            font-weight: bold;
            line-height: 29px;
        }
    }

    .actions {
        padding: 10px;

        button {
            width: 100%;
        }
    }
}

.error {
    color: var(--valencia);
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 14px;
}


