.quotation-summary {
    align-items: center;
    border: 1px solid var(--iron);
    display: grid;
    grid-template-columns: 1fr auto;

    @include media("<=tablet") {
        grid-template-columns: 1fr;
    }

    + .quotation-summary {
        margin-top: 24px;
    }

    .details {
        align-items: center;
        display: grid;
        grid-template-columns: minmax(0, 1fr) 240px;
        padding: 40px;

        @include media("<=tablet") {
            padding: 20px;
        }

        @include media("<=phone") {
            grid-template-columns: minmax(0, 100%);
        }
    }

    .left {
        border-right: 1px solid var(--iron);
        padding-right: 40px;

        @include media("<=phone") {
            border: 0;
            border-bottom: 1px solid var(--iron);
            padding: 0;
            padding-bottom: 20px;
        }

        .title {
            cursor: pointer;
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            line-height: 29px;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: lowercase;
            white-space: nowrap;
            width: 100%;
        }

        .last-update {
            font-size: 16px;
            line-height: 19px;
            margin-top: 10px;
            text-transform: lowercase;
        }
        .expired-date{
            font-size: 16px;
            line-height: 19px;
            margin-top: 10px;
            text-transform: lowercase;
        }
    }

    .right {
        padding-left: 40px;

        @include media("<=phone") {
            padding: 0;
            padding-top: 20px;
        }

        table {
            text-transform: lowercase;
            width: 100%;
        }

        td {
            text-align: right;
        }
    }

    .actions {
        align-items: center;
        align-self: stretch;
        border-left: 1px solid var(--iron);
        display: flex;
        gap: 10px;
        padding: 40px;

        @include media("<=tablet") {
            border-left: 0;
            border-top: 1px solid var(--iron);
            padding: 20px;
        }
    }
}
