.off-canvas {
    background-color: var(--white);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    max-width: 90%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: translateX(-30px);
    transition: transform 0.3s, opacity 0.1s;
    width: 380px;
    z-index: 1010;

    &.open {
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);
    }

    .top {
        align-items: center;
        border-bottom: 1px solid var(--iron);
        display: flex;
        flex-shrink: 0;
        height: 80px;
        margin: 0 40px;

        @include media("<=phone") {
            margin: 0 20px;
        }
    }

    .brand {
        color: var(--valencia);
        display: block;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        margin-left: 20px;
        margin-top: 1px;
        text-decoration: none;
        text-transform: lowercase;
    }

    .close {
        background-color: transparent;
        border: 0;
        height: 20px;
        padding: 0;
        position: relative;
        width: 20px;

        &::before,
        &::after {
            background-color: var(--black);
            content: "";
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    .menu {
        flex: 1;
        flex-shrink: 0;
        margin: 0 20px 0 40px;
        overflow-y: auto;
        padding-right: 20px;

        @include media("<=phone") {
            margin: 0 10px 0 20px;
            padding-right: 10px;
        }
    }

    .main-menu {
        margin: 0;
        padding: 0;

        a {
            cursor: pointer;
        }

        ul {
            margin: 0;
            padding: 0 40px 20px;

            li {
                padding: 12px 0;
            }
        }

        > li + li:not(.visible) {
            border-top: 1px solid var(--iron);
        }

        > li > span {
            align-items: center;
            display: flex;
            font-size: 18px;
            font-weight: bold;
            line-height: 22px;
            padding: 20px 40px;
            position: relative;
            text-transform: lowercase;
        }

        > li > span a {
            transition: none;
        }

        > li.visible > span a {
            color: var(--abbey);
            font-size: 16px;
            font-weight: normal;
            line-height: 19px;

            &:hover {
                color: inherit;
            }
        }

        > li.visible > ul > li {
            font-weight: bold;
            text-transform: lowercase;
        }

        > li.visible > ul > li .active {
            color: var(--valencia) !important;
        }

        .prev,
        .next {
            height: 100%;
            position: absolute;
            width: 24px;

            svg {
                width: 13px;
            }
        }

        .prev {
            left: 0;
            top: 2px;
            transform: rotate(180deg);
            visibility: hidden;
        }

        .next {
            right: 0;
            top: 0;
            visibility: visible;
        }

        li.visible {
            .prev {
                visibility: visible;
            }

            .next {
                visibility: hidden;
            }
        }

        li.hidden {
            display: none;
        }

        li.hidden ul {
            display: none;
        }

        li ul {
            display: none;
        }

        li.visible ul {
            display: block;
        }
    }

    .support-menu {
        border-top: 1px solid var(--iron);
        color: var(--abbey);
        font-weight: bold;
        margin: 0;
        padding: 0;
        text-transform: lowercase;

        a {
            cursor: pointer;
        }

        > li {
            margin-top: 24px;
        }
    }

    .lang {
        padding: 30px;

        ul {
            display: flex;
            margin-bottom: 0;
            padding-left: 0;
        }

        li {
            color: var(--abbey);
            cursor: pointer;
            font-weight: bold;
            text-transform: lowercase;
        }

        .active {
            color: var(--black);
        }

        li + li {
            margin-left: 16px;
        }
    }

    .width-150{
        width: 150px!important;
    }

    .width-180{
        width: 180px!important;
    }
    .width-220{
        width: 220px!important;
    }
    .width-230{
        width: 230px!important;
    }

    .switch-products{
        margin-left: 2px;
    }
}

.backdrop {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1005;

    &.open {
        pointer-events: all;
    }
}
