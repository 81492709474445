.alert-banner {
    align-items: center;
    background-color: var(--black);
    color: var(--white);
    display: flex;
    padding: 16px 24px;

    .close {
        background-color: transparent;
        border: 0;
        height: 20px;
        margin-left: 10px;
        padding: 0;
        position: relative;
        width: 20px;

        .label {
            display: none;
        }

        &::before,
        &::after {
            background-color: var(--white);
            content: "";
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}
