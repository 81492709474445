.counter-container {
  border: 1px solid var(--iron);
  flex: 1;
  min-width: 220px;
  max-width: 300px;
  height: 150px;
  padding: 16px 8px !important;
}

.counter-label {
  font-weight: bold;
  margin-bottom: 0%;
  color: #7c7d7e;
  font-size: 1.25rem;
}

.counter-number {
  font-size: 2.75rem;
  text-align: center;
}