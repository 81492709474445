.home.main {
    padding: 40px 0 70px;

    @include media("<=phone") {
        padding-left: 0;
        padding-right: 0;
    }

    .grid {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);

        @include media("<=phone") {
            grid-template-columns: 1fr;
        }
    }
}
