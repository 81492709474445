:root {
    --bombay: #a9aaac;
    --abbey: #52545a;
    --alto: #cecece;
    --black: #000;
    --green-haze: #009a6c;
    --iron: #e0e0e2;
    --pampas: #f4f2f0;
    --valencia: #dc4547;
    --white: #fff;
    --whild-sand: #f6f6f6;
}
