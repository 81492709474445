.product-search {
    .page-header {
        padding-bottom: 20px;
    }

    .search-form {
        margin-top: 40px;

        @include media("<=phone") {
            margin-top: 10px;
        }

        form {
            display: grid;
            gap: 24px;

            @include media("<=phone") {
                gap: 5px;
                grid-template-columns: 1fr;
            }
        }

        .form-configurable {
            grid-template-columns: 1fr 180px;
        }

        .form-configured {
            grid-template-columns: 1fr 180px 180px 130px;
        }

        .form-outlet {
            grid-template-columns: 1fr 180px 180px 130px;
        }

        .search-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;

            button {
                margin-right: -20px;
                padding: 0 20px;
                width: auto;

                svg {
                    width: 18px;
                }
            }
        }

        .category-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;
            min-height: 51px;

            .dropdown-toggle {
                justify-content: space-between;
            }

            .dropdown,
            .dropdown-menu {
                width: 100%;
                div > .dropdown-item {
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                }
            }
        }

        .brand-code-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;
            min-height: 51px;

            .dropdown-toggle {
                justify-content: space-between;
            }

            .dropdown,
            .dropdown-menu {
                width: 100%;
                div > .dropdown-item {
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                }
            }
        }

        .quantity-input {
            align-items: center;
            border-bottom: 1px solid var(--black);
            display: flex;
            min-height: 51px;

            .dropdown-toggle {
                justify-content: space-between;
            }

            .dropdown,
            .dropdown-menu {
                width: 100%;
                div > .dropdown-item {
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                }
            }
        }
    }

    .filters-toggle-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 60px;

        @include media(">phone") {
            display: none;
        }
    }

    .filters-toggle {
        align-items: center;
        border-radius: 100px;
        display: flex;
        margin: auto;

        @include media(">phone") {
            display: none;
        }

        svg {
            margin-left: 20px;
            width: 20px;
        }

        &.is-sticky {
            position: fixed;
            top: 100px;
        }
    }

    .filters {
        @include media("<=phone") {
            background-color: var(--white);
            display: none;
            left: 0;
            height: 100vh;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 2000;
        }

        &.show {
            @include media("<=phone") {
                display: flex;
                flex-direction: column;
            }
        }

        .filters-header {
            display: none;

            @include media("<=phone") {
                align-items: center;
                display: flex;
                justify-content: center;
                padding: 20px 10px;
                position: relative;
            }

            span {
                font-weight: bold;
                text-transform: lowercase;
            }

            .close {
                background-color: transparent;
                border: 0;
                height: 20px;
                left: 20px;
                padding: 0;
                position: absolute;
                top: 20px;
                width: 20px;

                &::before,
                &::after {
                    background-color: var(--black);
                    content: "";
                    display: block;
                    height: 2px;
                    position: absolute;
                    width: 100%;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }

        .filters-input {
            @include media("<=phone") {
                flex: 1;
                overflow: auto;
                padding: 10px;
            }
        }

        .filters-footer {
            border-top: 1px solid var(--iron);
            display: none;
            padding: 10px;

            button {
                width: 100%;
            }

            @include media("<=phone") {
                display: block;
            }
        }

        h5 {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin-left: 40px;
            text-transform: lowercase;
        }

        .label {
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            margin-bottom: 8px;
            margin-left: 40px;
            text-transform: lowercase;
        }
    }

    .filters-row {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 24px;
        row-gap: 24px;

        @include media("<=phone") {
            gap: 24px;
            grid-template-columns: 1fr;
        }

        & > div + div .input-wrapper {
            padding-left: 40px;
            position: relative;

            .icon {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        & > div:nth-child(even) .input-wrapper {
            @include media(">phone") {
                border-left: 0 !important;
            }
        }

        .MTO-checkbox {
            margin-bottom: 8px;
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
        }
    }

    .summary {
        background-color: var(--whild-sand);
        margin: 26px -40px 20px;
        padding: 20px 40px;

        @include media("<=phone") {
            margin: 16px 0;
            padding: 20px 10px;
        }

        .total span {
            @include media("<=phone") {
                display: none;
            }


        }

        .export-button {
            color: #fff !important;
            padding: 10px;
            background: #ef3340;
            border-radius: 3px;
            font-weight: bold;
            margin-left: 30px;
            cursor: pointer;
        }
    }

    .summary-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .total {
            color: var(--bombay);
            font-size: 14px;
            line-height: 17px;
        }

        .sort {
            align-items: center;
            display: flex;

            span {
                color: var(--bombay);
                font-size: 14px;
                line-height: 17px;
                margin-right: 10px;
            }
        }
    }

    .loader {
        display: flex;
        justify-content: center;
        margin: 40px 0;

        .spinner-border {
            border-color: var(--iron);
            border-right-color: transparent;
        }
    }

    .grid {
        display: grid;
        gap: 40px 26px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        -ms-grid-rows: 1fr;
        grid-auto-rows: 1fr;

        @include media("<=phone") {
            grid-template-columns: 1fr;
            padding: 0 10px;
        }
    }

    .products-not-found {
        font-size: 36px;
        font-weight: bold;
        line-height: 43px;
        margin-top: 80px;
    }
}
