.category-card {
    .category-card-wrapper {
        cursor: pointer;
        overflow: hidden;
        padding-bottom: 75%;
        position: relative;
    }

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        transition: transform 0.3s;
        width: 100%;
    }

    .category-card-wrapper:hover img {
        transform: scale(1.05);
    }

    .overlay {
        background: linear-gradient(transparent 0 50%, var(--black) 100%);
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .content {
        bottom: 34px;
        left: 40px;
        position: absolute;
        right: 40px;
        top: 34px;

        @include media("<=phone") {
            bottom: 25px;
            left: 30px;
            right: 30px;
            top: 25px;
        }
    }

    .title {
        color: var(--white);
        font-size: 30px;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        text-transform: lowercase;
    }

    .subcategories {
        margin-bottom: 0;
        margin-top: 10px;
        padding-left: 0;

        @include media("<=phone") {
            padding: 0 10px;
        }

        li {
            color: var(--abbey);
            display: inline-block;
            font-size: 16px;
            line-height: 19px;
            text-transform: lowercase;
        }

        li + li:before {
            content: "/";
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
