.quotation-detail {
    .summary {
        align-items: center;
        border-bottom: 1px solid var(--iron);
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 0 40px 40px;

        @include media("<900px") {
            display: block;
        }

        @include media("<=phone") {
            padding: 0 10px 40px;
        }

        .details {
            text-transform: lowercase;

            .expiration {
                color: var(--valencia);
            }
        }

        .actions {
            align-items: center;
            display: flex;

            @include media("<900px") {
                margin-top: 20px;
            }

            @include media("<=phone") {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(2, 1fr);
            }

            button {
                align-items: center;
                display: flex;
                justify-content: center;

                svg {
                    margin-left: 10px;
                    width: 22px;

                    @include media("<=phone") {
                        display: none;
                    }
                }
            }

            button + button {
                margin-left: 10px;

                @include media("<=phone") {
                    margin: 0;
                }
            }
        }
    }

    .grid {
        align-items: start;
        display: grid;
        gap: 24px;
        grid-template-columns: 3fr minmax(300px, 1fr);

        @include media("<=tablet") {
            gap: 60px;
            grid-template-columns: 1fr;
        }

        .products {
            table {
                border: 1px solid var(--iron);
                width: 100%;

                tr {
                    @include media("<=phone") {
                        display: grid;
                    }
                }

                tr + tr {
                    border-top: 1px solid var(--iron);
                }

                td {
                    vertical-align: middle;
                }

                .info {
                    padding: 20px 10px;
                }

                .image-details {
                    align-items: center;
                    display: flex;
                }

                .image {
                    flex-shrink: 0;
                    width: 130px;
                }

                .details {
                    font-size: 16px;
                    line-height: 19px;
                    padding-left: 10px;

                    .title {
                        font-weight: bold;
                        text-transform: lowercase;
                    }

                    .options {
                        font-size: 14px;
                        line-height: 18px;
                        margin-top: 10px;
                        text-transform: lowercase;

                        span {
                            display: block;
                        }
                    }
                }

                .actions {
                    padding: 20px 24px 20px 10px;
                    width: 1%;

                    @include media("<=phone") {
                        padding-top: 0;
                    }

                    .remove {
                        font-size: 14px;
                        line-height: 17px;
                        padding: 16px;
                    }
                }

                .actions-wrapper {
                    display: flex;
                }

                .quantity {
                    padding: 20px 24px;
                    width: 1%;
                }
            }
        }

        .subtotal-row {
            align-items: center;
            border: 1px solid var(--iron);
            border-top: 0;
            display: flex;
            justify-content: space-between;
            padding: 20px 24px 20px 10px;

            .left {
                align-items: center;
                display: flex;
                text-transform: lowercase;
            }

            .pictogram {
                margin-right: 10px;
                width: 110px;

                svg {
                    display: block;
                    margin: auto;
                    width: 42px;
                }
            }
        }

        .personal-details{
            margin: 40px 40px 10px;
        }

        .notes {
            margin-top: 40px;

            h2 {
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                margin: 0 40px 10px;
                text-transform: lowercase;

                @include media("<=tablet") {
                    margin: 0 10px 10px;
                }
            }

            > div {
                border: 1px solid var(--iron);
                border-radius: 0;
                outline: 0;
                padding: 20px 40px;
                resize: none;
                width: 100%;

                @include media("<=tablet") {
                    padding: 20px 24px;
                }
            }
        }

        & > .right {
            border: 1px solid var(--iron);

            .title {
                border-bottom: 1px solid var(--iron);
                font-size: 16px;
                font-weight: bold;
                line-height: 19px;
                margin-bottom: 0;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .subtotal {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .discount {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .transportation-fee {
                align-items: center;
                border-bottom: 1px solid var(--iron);
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;
            }

            .total {
                display: flex;
                font-size: 16px;
                justify-content: space-between;
                line-height: 19px;
                padding: 20px 24px;
                text-transform: lowercase;

                .discounted-total {
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 43px;
                }
            }

            .actions {
                padding: 0 24px 24px;

                button {
                    width: 100%;
                }

                button + button {
                    margin-top: 16px;
                }
            }
        }
    }
}
