.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-background {
    background-position: center;
    background-size: cover;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.page-header {
    padding: 0 40px 40px;

    @include media("<=phone") {
        padding: 0 10px 40px;
    }

    .title {
        font-size: 52px;
        font-weight: bold;
        line-height: 62px;
        margin-bottom: 0;
        text-transform: initial;

        @include media("<=phone") {
            font-size: 36px;
            line-height: 43px;
        }
    }
}
