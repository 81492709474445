.popover {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 12px rgba(0, 0, 0, 20%);
    max-width: none !important;
    width: 340px;

    p:last-child {
        margin-bottom: 0;
    }
}

.popover-body {
    font-family: "Calligaris" !important;
    color: inherit !important;
    padding: 0 !important;
}

.popover > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    border-bottom: 0 !important;
}
