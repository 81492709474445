.footer {
    background-color: var(--pampas);
    padding: 20px 40px;

    @include media("<=phone") {
        padding: 20px 10px;
    }
}

.footer-wrapper {
    align-items: center;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 3fr 1fr;

    @include media("<=phone") {
        gap: 24px 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .logo {
        color: var(--valencia);
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        text-transform: lowercase;

        @include media("<=phone") {
            grid-column: 1/2;
            grid-row: 1/2;
        }
    }

    .copy {
        color: var(--bombay);
        font-size: 12px;
        line-height: 14px;
        text-align: center;

        @include media("<=phone") {
            grid-column: 1/3;
            grid-row: 2/3;
        }
    }

    .pictogram {
        justify-self: right;
        width: 42px;

        @include media("<=phone") {
            grid-column: 2/3;
            grid-row: 1/2;
        }
    }
}
