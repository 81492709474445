.pagination {
    border: 1px solid var(--iron);
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin-top: 60px;

    @include media("<=phone") {
        border: none;
        padding: 0 10px;
    }

    ul.pages {
        align-self: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 12px;

        @include media("<=phone") {
            display: none;
        }

        li {
            cursor: pointer;
            font-weight: bold;
            margin: 6px;
            padding: 6px;
        }

        li.active {
            color: var(--valencia);
            cursor: default;
        }
    }

    button {
        align-items: center;
        color: var(--abbey);
        display: flex;
        background-color: transparent;
        border: 0;
        justify-content: center;
        padding: 0;
        width: 80px;

        @include media("<=phone") {
            border: 1px solid var(--iron);
        }

        svg {
            width: 16px;
        }

        .label {
            display: none;
        }
    }

    .prev {
        border-right: 1px solid var(--iron);

        svg {
            transform: rotate(180deg);
        }
    }

    .next {
        border-left: 1px solid var(--iron);
    }
}
