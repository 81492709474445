.form-card {
    background-color: var(--white);
    width: 460px;
    padding: 45px 60px 60px;

    @include media("<=phone") {
        padding: 40px 22px 60px;
        width: 100%;
    }
}

.form-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 38px;
    margin-bottom: 14px;
    text-transform: lowercase;

    @include media("<=phone") {
        font-size: 40px;
        line-height: 42px;
    }
}

.form-description {
    margin-bottom: 24px;
}

.form-row + .form-row {
    margin-top: 32px;
}

label {
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="search"],
input[type="url"] {
    border: 1px solid var(--alto);
    display: block;
    font-size: 14px;
    line-height: 17px;
    outline: 0;
    padding: 16px;
    transition: all 0.3s linear;
    width: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus {
    border-color: var(--black);
    box-shadow: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="tel"]::placeholder,
input[type="search"]::placeholder,
input[type="url"]::placeholder {
    color: var(--bombay);
    text-transform: lowercase;
}

button {
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    padding: 0;
    text-align: center;
}

.button {
    background: var(--black);
    border: 0;
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 20px 27px;
    text-transform: lowercase;
    transition: all 0.2s;
}

.button[disabled] {
    background-color: var(--whild-sand);
    color: var(--black);
    cursor: not-allowed;
}

.button-alt {
    background: var(--pampas);
    border: 0;
    color: var(--black);
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 20px 27px;
    text-transform: lowercase;
}

.only-icon {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 0;
    width: 50px;

    .label {
        display: none;
    }

    svg {
        width: 26px;
    }
}

.form-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.form-actions a {
    border-bottom: 1px solid;
    color: var(--abbey) !important;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-decoration: none;
    transition: all 0.3s linear;
}

.form-actions a:hover {
    color: var(--black) !important;
}

.form-alert {
    margin-top: 14px;

    &.success {
        color: var(--green-haze);
    }

    &.danger {
        color: var(--valencia);
    }
}

.search-form form {
    input[type="text"] {
        border: 0;
        font-size: 16px;
        line-height: 19px;
        padding: 15px 0;
    }
}

.input-wrapper {
    //align-items: center;
    border: 1px solid var(--iron);
    //display: flex;
    padding-left: 40px;
    position: relative;

    .icon {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px;

        svg {
            color: var(--bombay);
            width: 14px;
        }
    }

    .rbt {
        width: 100%;
    }

    .rbt-input-wrapper {
        flex-wrap: nowrap !important;
    }
}

.form-control {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 16px 16px 16px 0 !important;
}

.form-control input::placeholder {
    text-transform: lowercase;
}

.rbt-token {
    background-color: transparent !important;
    border-radius: 0 !important;
    color: var(--black) !important;
    flex-shrink: 0;
    font-weight: bold;
    margin: -4px 0 !important;
    padding: 0 !important;
    text-transform: lowercase;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
    font-size: 24px;
}

.rbt-input-multi.focus {
    border-color: var(--iron) !important;
    box-shadow: none !important;
}

/*.rbt-input-multi .rbt-input-wrapper {
    flex-wrap: nowrap !important;
}*/

.dropdown-menu {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.2);
    color: var(--black) !important;
    margin-top: -1px !important;
}

.slider-container {
    margin-top: 10px;
    position: relative;
}

.slider {
    position: relative;
    width: 200px;
}

.slider-track,
.slider-range {
    height: 2px;
    position: absolute;
}

.slider-track {
    background-color: var(--iron);
    width: 100%;
    z-index: 1;
}

.slider-range {
    background-color: var(--black);
    z-index: 2;
}

.slider-values {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}
