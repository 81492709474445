.header {
    background-color: var(--white);
    border-bottom: 1px solid var(--bombay);
    left: 0;
    position: fixed;
    top: 0;
    transition: transform 0.4s;
    width: 100%;
    z-index: 1001;

    &.transparent {
        background-color: transparent;
        border-bottom: 0;
        position: static;
    }

    &.hidden:not(.transparent) {
        transform: translateY(-100%);
    }
}

.header-wrapper {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: space-between;
    padding: 40px 0;

    @include media("<=phone") {
        padding: 30px 10px;
    }

    .transparent & {
        height: 120px;
    }

    .left,
    .right {
        align-items: center;
        display: flex;
    }

    .burger {
        background-color: transparent;
        border: 0;
        display: flex;
        flex-direction: column;
        height: 12px;
        justify-content: space-between;
        padding: 0;
        width: 20px;

        span,
        &::before,
        &::after {
            background-color: var(--black);
            content: "";
            display: block;
            height: 2px;
            width: 100%;
        }
    }

    .logo {
        color: var(--valencia) !important;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        margin-left: 20px;
        text-decoration: none;
        text-transform: lowercase;
    }

    .transparent & .logo {
        margin-left: 0;

        @include media("<=phone") {
            font-size: 36px;
            line-height: 43px;
        }
    }

    .pictogram {
        color: var(--white);
        width: 52px;
    }

    .menu {
        align-items: center;
        color: var(--abbey);
        display: flex;

        @include media("<=phone") {
            margin: 0 -10px 0 0;
        }

        .cart {
            align-items: center;
            display: inline-flex;

            .label {
                margin-right: 8px;

                @include media("<=phone") {
                    display: none;
                }
            }

            .counter {
                align-items: center;
                background-color: var(--bombay);
                border-radius: 50%;
                color: var(--white);
                display: inline-flex;
                font-weight: bold;
                height: 25px;
                justify-content: center;
                width: 25px;
            }
        }

        .history {
            @include media("<=phone") {
                display: none;
            }
        }

        .logout {
            align-items: center;
            display: flex;
            font-weight: normal;
            text-transform: uppercase;

            .label {
                margin-right: 10px;

                @include media("<=phone") {
                    display: none;
                }
            }

            svg {
                width: 18px;
            }
        }
    }

    .menu > * {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        outline: 0;
        padding: 6px;
        text-transform: lowercase;
        margin: 0 9px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.dummy-header {
    height: 80px;
}

.search-popover {
    margin-left: -5px;
    width: 100% !important;

    .popover-body {
        padding: 60px 0 80px !important;

        @include media("<=phone") {
            padding: 40px 0 60px !important;
        }
    }

    form {
        display: grid;
        font-size: 16px;
        gap: 24px;
        grid-template-columns: 1fr 180px;
        line-height: 19px;
        padding: 0 40px;

        @include media("<=phone") {
            gap: 5px;
            grid-template-columns: 1fr;
            padding: 0 10px;
        }
    }

    .search-input {
        align-items: center;
        border-bottom: 1px solid var(--black);
        display: flex;

        input {
            border: 0;
            font-size: 16px;
            line-height: 19px;
            padding-left: 0;
            padding-right: 0;

            &::placeholder {
                color: var(--bombay);
                text-transform: lowercase;
            }
        }

        button {
            margin-right: -20px;
            padding: 0 20px;
            width: auto;

            svg {
                width: 18px;
            }
        }
    }

    .category-input {
        align-items: center;
        border-bottom: 1px solid var(--black);
        display: flex;
        min-height: 51px;

        .dropdown-toggle {
            justify-content: space-between;
        }

        .dropdown,
        .dropdown-menu {
            width: 100%;
        }
    }
}
