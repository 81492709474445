.quotations {
    .search-form {
        border-bottom: 1px solid var(--black);
        display: flex;
        margin: 0 40px 60px;
        max-width: 960px;

        @include media("<=phone") {
            margin: 0 10px 60px;
        }

        input[type="text"] {
            border: 0;
            flex: 1;
            padding-left: 0;
            padding-right: 0;
        }

        input[type="text"]::placeholder {
            text-transform: lowercase;
        }

        button {
            margin-right: -20px;
            padding: 0 20px;
            width: auto;

            svg {
                width: 18px;
            }
        }
    }

    .total-quotations {
        color: var(--bombay);
        font-size: 14px;
        line-height: 17px;
        margin: 0 40px 18px;

        @include media("<=phone") {
            margin: 0 10px 18px;
        }
    }

    .quotations-not-found {
        font-size: 36px;
        font-weight: bold;
        line-height: 43px;
        margin-top: 80px;
    }
}
