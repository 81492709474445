.product-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        border-bottom: 1px solid var(--iron);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content {
        font-size: 16px;
        line-height: 19px;
        margin-top: 10px;
    }

    .title {
        font-size: inherit;
        font-weight: bold;
        line-height: inherit;
        text-transform: lowercase;
    }

    .meta {
        margin-top: 8px;
    }

    .meta > * {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .description2 {
        text-transform: lowercase;
    }

    .code {
        display: none;
    }

    .details {
        color: var(--abbey);
        margin-top: 8px;
    }
}
