.dashboard-section {
  margin-bottom: 30px;

  h3 {
    color: var(--valencia); //#dc4547
    font-weight: bold;
  }

  .dashboard-dropdown {
    font-size: 1.5rem;
  }

  .counter-boxes-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
  }
}

.dashboard-search-form {
  border-bottom: 1px solid var(--black);
  display: flex;
  margin: 30px 40px 40px;

  @include media("<=phone") {
    margin: 0 10px 60px;
  }

  input[type="text"] {
    border: 0;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
  }

  input[type="text"]::placeholder {
    text-transform: lowercase;
  }

  button {
    margin-right: -20px;
    padding: 0 20px;
    width: auto;

    svg {
      width: 18px;
    }
  }
}

.category-info {
  padding: 20px;
  border-top: 1px solid var(--iron);
  display: flex;
  justify-content: space-between;

  p {
    &.title {
      font-size: 1.25rem;
      padding-left: 10px;
    }

    &.quantity {
      padding-right: 24px;
    }

    margin: 0
  }
}

.dashboard-product {

  .actions-wrapper span {
    font-size: 2rem;
  }

  .products {
    table {
      border: 1px solid var(--iron);
      width: 100%;

      tr {
        @include media("<=phone") {
          display: grid;
        }
      }

      tr+tr {
        border-top: 1px solid var(--iron);
      }

      td {
        vertical-align: middle;
      }

      .info {
        padding: 20px 10px;
      }

      .image-details {
        align-items: center;
        display: flex;
      }

      .image {
        flex-shrink: 0;
        width: 130px;

        @include media("<=phone") {
          width: 100px;
        }
      }

      .details {
        font-size: 16px;
        line-height: 19px;
        padding-left: 10px;

        .title {
          font-weight: bold;
          text-transform: lowercase;
        }

        .options {
          font-size: 14px;
          line-height: 18px;
          margin-top: 10px;
          text-transform: lowercase;

          span {
            display: block;
          }
        }
      }

      .actions {
        padding: 20px 24px 20px 10px;
        width: 1%;

        @include media("<=phone") {
          padding-top: 0;
        }

        .remove {
          font-size: 14px;
          line-height: 17px;
          padding: 16px;
        }
      }

      .actions-wrapper {
        display: flex;
      }

      .quantity {
        padding: 20px 24px;
        width: 1%;
      }
    }
  }
}