.main.forgot-pwd {
    align-items: center;
    display: flex;
    padding: 0 0 40px;

    @include media("<=phone") {
        display: block;
        margin-top: 60px;
    }
}
